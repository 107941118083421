/* global axios, rootDir */

import mediator from "libs/mediator";

export default (function() {
  function run() {
    let showNewsletterBtn = document.querySelector('.attach-newsletter-popup');

    if (!showNewsletterBtn) {
      return
    }

    showNewsletterBtn.addEventListener('click', getNewsletterForm);
  }

  function showModal(data) {
    let modal = document.querySelector("#my-modal .modal__content");
    modal.innerHTML = data;
    mediator.trigger("modal:show");
    mediator.trigger("newsletter-modal:show");
  }

  function getNewsletterForm() {
    axios
      .get(rootDir + '/modals/newsletter_subscribe')
      .then(response => {
        showModal(response.data)
      });
  }

  return {
    run: run
  }
})();
