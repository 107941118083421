import log from "libs/Utils/log";
import mediator from "libs/mediator";

export default (function() {
  function run(){
    log("ShowMore:init");

    attachEventListener();
    mediator.on("showmore", attachEventListener);
  }

  function attachEventListener() {
    let showMores = document.querySelectorAll('[data-module="show-more"]');

    showMores.forEach(function(element) {
      element.addEventListener("click", handleShowMoreClick);
    });
  }

  function handleShowMoreClick(event) {
    event.preventDefault();
    showMoreDescriptionDisplay(event)
  }

  function showMoreDescriptionDisplay(event) {
    let element = event.target;
    let description = element.previousElementSibling;
    let descriptionClass = description.classList[0];

    if(description.classList.contains(`${descriptionClass}--small`)) {
      description.classList.remove(`${descriptionClass}--small`);

      if (element.classList.contains('coupon__toggle')) {
        element.innerHTML = '<i class="roberto-chevron-up"></i> <span>' + element.getAttribute('data-less-text') + '</span>';
      } else
      {
        element.innerHTML = element.getAttribute('data-less-text');
      }
    } else {
      description.classList.add(`${descriptionClass}--small`);
      if (element.classList.contains('coupon__toggle')) {
        element.innerHTML = '<i class="roberto-chevron-down"></i> <span>' + element.getAttribute('data-more-text') + '</span>';
      } else {
        element.innerHTML = element.getAttribute('data-more-text');
      }
    }

    let couponToggle = document.getElementsByClassName("coupon__toggle");
    let arr = [...couponToggle];

    arr.forEach((toggle) => {
      toggle.addEventListener("click", () => {
        let parentCoupon = toggle.closest(".padding");
        let descriptionToToggle = parentCoupon?.querySelector(".coupon__description");

        if (descriptionToToggle) {
          if (!descriptionToToggle.classList.contains("coupon__description--small")) {
            let allDescriptions = document.querySelectorAll(".coupon__description");
            allDescriptions.forEach((desc) => {
              if (desc !== descriptionToToggle) {
                desc.classList.add("coupon__description--small");
                if (element && description.classList.contains(`${descriptionClass}--small`)) {
                  element.innerHTML = '<i class="roberto-chevron-down"></i> <span>' + element.getAttribute('data-more-text') + '</span>';
                } else {
                  element.innerHTML = '<i class="roberto-chevron-up"></i> <span>' + element.getAttribute('data-less-text') + '</span>';
                }
              }

            });
          }
        }
      });
    });

  }

  return {
    run: run
  }
})()
