import log from "../Utils/log";

export default (function() {
  function run() {
    let showAllBtn = document.querySelector("[data-show-all-btn]");
    const ClassItemsShow = 'list-coupons-as-items--show';

    if(!showAllBtn) {
      return;
    }

    log("TopXCoupons:init");

    showAllBtn.addEventListener("click", function(event) {
      event.preventDefault();
      let coupons = document.querySelector(".list-coupons-as-items:nth-child(2)");
      coupons.classList.add(ClassItemsShow);
      this.classList.add('hidden');
    });
  }

  return {
    run: run
  };
})();
