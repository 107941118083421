/* eslint no-console: 0 */

import Facade from "libs/Facade";
import desktopNavigation from "../modules/desktopNavigation";
import mobileNavigation from "../modules/mobileNavigation";
import footerNavigation from "../modules/footerNavigation";
import TopXCoupons from "libs/widgets/TopXCoupons";
import cardText from "../modules/cardText";
import showMore from "../modules/showMore";
import ToggleDropdown from "../modules/toggleDropdown";
import topBarNewsletter from "../modules/topBarNewsletter";
import HeroCountdown from "libs/modules/HeroCountdown";

import {
  CouponsGrid,
  FeaturedCoupons,
  QuickLinks,
  Carousel,
  PremiumSlider,
  OfferCarousel,
  PremiumCampaignSlider,
  EarthmarkShops
} from "libs/widgets";

class AppFacade extends Facade {
  start() {
    super.start();
  }

  Common() {
    super.Common();
    desktopNavigation.run();
    mobileNavigation.run();
    TopXCoupons.run();
    footerNavigation.run();
    showMore.run();
    topBarNewsletter.run();
    HeroCountdown.run();
  }

  Coupons() {
    super.Coupons();
  }

  Widgets() {
    CouponsGrid.run();
    FeaturedCoupons.run();
    QuickLinks.run();
    Carousel.run();
    PremiumSlider.run();
    OfferCarousel.run();
    PremiumCampaignSlider.run();
    EarthmarkShops.run();
  }

  cardText() {
    cardText.run();
  }

  ToggleDropdown() {
    ToggleDropdown.run();
  }
}

const App = new AppFacade();

export default App;
